exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-promotion-js": () => import("./../../../src/pages/promotion.js" /* webpackChunkName: "component---src-pages-promotion-js" */),
  "component---src-pages-submitted-js": () => import("./../../../src/pages/submitted.js" /* webpackChunkName: "component---src-pages-submitted-js" */),
  "component---src-pages-tenant-antipodean-js": () => import("./../../../src/pages/tenant/antipodean.js" /* webpackChunkName: "component---src-pages-tenant-antipodean-js" */),
  "component---src-pages-tenant-chilis-js": () => import("./../../../src/pages/tenant/chilis.js" /* webpackChunkName: "component---src-pages-tenant-chilis-js" */),
  "component---src-pages-tenant-coffee-bean-js": () => import("./../../../src/pages/tenant/coffee-bean.js" /* webpackChunkName: "component---src-pages-tenant-coffee-bean-js" */),
  "component---src-pages-tenant-dipndip-js": () => import("./../../../src/pages/tenant/dipndip.js" /* webpackChunkName: "component---src-pages-tenant-dipndip-js" */),
  "component---src-pages-tenant-halzan-by-wheelers-js": () => import("./../../../src/pages/tenant/halzan-by-wheelers.js" /* webpackChunkName: "component---src-pages-tenant-halzan-by-wheelers-js" */),
  "component---src-pages-tenant-index-js": () => import("./../../../src/pages/tenant/index.js" /* webpackChunkName: "component---src-pages-tenant-index-js" */),
  "component---src-pages-tenant-kanada-ya-js": () => import("./../../../src/pages/tenant/kanada-ya.js" /* webpackChunkName: "component---src-pages-tenant-kanada-ya-js" */),
  "component---src-pages-tenant-minimelts-js": () => import("./../../../src/pages/tenant/minimelts.js" /* webpackChunkName: "component---src-pages-tenant-minimelts-js" */),
  "component---src-pages-tenant-miraku-js": () => import("./../../../src/pages/tenant/miraku.js" /* webpackChunkName: "component---src-pages-tenant-miraku-js" */),
  "component---src-pages-tenant-richiamo-js": () => import("./../../../src/pages/tenant/richiamo.js" /* webpackChunkName: "component---src-pages-tenant-richiamo-js" */),
  "component---src-pages-tenant-the-holy-guacamole-js": () => import("./../../../src/pages/tenant/the-holy-guacamole.js" /* webpackChunkName: "component---src-pages-tenant-the-holy-guacamole-js" */),
  "component---src-pages-tenant-the-loft-js": () => import("./../../../src/pages/tenant/the-loft.js" /* webpackChunkName: "component---src-pages-tenant-the-loft-js" */),
  "component---src-pages-tenant-tree-bar-js": () => import("./../../../src/pages/tenant/tree-bar.js" /* webpackChunkName: "component---src-pages-tenant-tree-bar-js" */),
  "component---src-pages-tenant-winter-warmer-js": () => import("./../../../src/pages/tenant/winter-warmer.js" /* webpackChunkName: "component---src-pages-tenant-winter-warmer-js" */)
}

